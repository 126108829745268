import { MutationTree } from 'vuex'
import { State } from '@/store/index'
import Connection from '@/api/models/Connection'
import Account from '@/api/models/Account'

export enum RootStateMutations {
    setLoading = 'setLoading',
    setConnection = 'setConnection',
    updateConnection = 'updateConnection',
    updateAccount = 'updateAccount',
    setAccounts = 'setAccounts',
    deleteAccount = 'deleteAccount'
}

export const mutations: MutationTree<State> = {
    [RootStateMutations.setLoading] (state: State, isLoading: boolean) {
        state.loading = isLoading
    },
    [RootStateMutations.setConnection] (state: State, connection: Connection) {
        state.connection = connection
    },
    [RootStateMutations.setAccounts] (state: State, accounts: Array<Account>) {
        state.connection = {
            ...state.connection,
            accounts: accounts
        }
    },
    [RootStateMutations.updateAccount] (state: State, acc: Account) {
        state.connection.accounts = state.connection.accounts?.map((account) => {
            if (account.id === acc.id) {
                account.commentsEnabled = acc.commentsEnabled
                account.igCommentsEnabled = acc.igCommentsEnabled
                account.igMentionsEnabled = acc.igMentionsEnabled
                account.fbHistoryUploaded = acc.fbHistoryUploaded
                account.igHistoryUploaded = acc.igHistoryUploaded
            }

            return account
        })
    },
    [RootStateMutations.deleteAccount] (state: State, id: number) {
        state.connection.accounts = state.connection.accounts?.filter((acc) => acc.id !== id)
    },
    [RootStateMutations.updateConnection] (state: State, conn: Connection) {
        state.connection = conn
    }
}
