import axios, { AxiosInstance } from 'axios'
import qs from 'qs'

export interface ApiService{
    get<Req, Res>(url: string, data?: Req): Promise<Res>;
    post<Req, Res>(url: string, data: Req): Promise<Res>;
    put<Req, Res>(url: string, data: Req): Promise<Res>;
    patch<Req, Res>(url: string, data: Req): Promise<Res>;
}

const client = axios.create({
    withCredentials: true,
    responseType: 'json',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf8',
        'X-From-Frame': localStorage.getItem('isFrame')
    }
})

export function getAuthToken (): string {
    return localStorage.getItem('token') || ''
}

export function setAuthToken (token: string) {
    client.defaults.headers.clientId = token
    localStorage.setItem('token', token)
}

export function deleteAuthToken () {
    delete client.defaults.headers.clientId
    localStorage.removeItem('token')
}

export default class Service implements ApiService {
    private client: AxiosInstance;

    constructor () {
        this.client = client
    }

    public async get<Request, Response> (url: string, data?: Request): Promise<Response> {
        const response = await this.client.get<Response>(url, {
            params: data,
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
        })

        return response.data
    }

    public async patch<Request, Response> (url: string, data: Request): Promise<Response> {
        const response = await this.client.patch<Response>(url, data)

        return response.data
    }

    public async post<Request, Response> (url: string, data: Request): Promise<Response> {
        const response = await this.client.post<Response>(url, data)

        return response.data
    }

    public async put<Request, Response> (url: string, data: Request): Promise<Response> {
        try {
            const response = await this.client.put<Response>(url, data)

            return response.data
        } catch (e) {
            return e
        }
    }
}
