import { RouteConfig } from 'vue-router'
import i18n from '@/i18n'

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'connect',
        meta: { title: i18n.t('integration') },
        component: () => import('@/modules/connect/views/Connect.vue')
    }
]

export default routes
