const appEvent = 'app-event'
const appEventTypes = {
    fbConnectedEvent: 'fb-connected',
    fbConnectErrorEvent: 'fb-connect-error',
    fbOAuthLogin: 'fb-oauth-login'
}

interface Message {
    type?: string;
    code?: string;
    message?: string;
}

export default class PostMessages {
    private readonly type: string
    private parent!: MessageEventSource | null

    constructor () {
        this.type = 'fbmessenger'

        this.initEventListeners()
    }

    initEventListeners (): void {
        // @ts-ignore
        window.addEventListener(appEvent, (event: CustomEvent) => {
            let message = ''
            switch (event.detail.type) {
            case appEventTypes.fbConnectedEvent:
                this.sendConnectedMessage()
                break

            case appEventTypes.fbConnectErrorEvent:
                if (event.detail && event.detail.message) {
                    message = event.detail.message
                }

                // @ts-ignore
                this.sendConnectErrorMessage(message)
                break

            case appEventTypes.fbOAuthLogin:
                this.sendEvent(event.detail.type)
                break
            }
        })
    }

    listenMessage (event: MessageEvent): void {
        try {
            const message = JSON.parse(event.data)

            switch (message.type) {
            case 'init':
                this._init(event)
                break
            }
        } catch (e) {
        }
    }

    _init (event: MessageEvent): void {
        this.parent = event.source
    }

    sendReadyMessage (): void {
        const interval = setInterval(() => {
            if (!this.parent) {
                this.parent = window.top
            }

            this._send(this._createMessage('ready'))
            clearInterval(interval)
        }, 5000)
    }

    sendEvent (event: string, message = ''): void {
        this._send(this._createMessage(event, message))
    }

    sendConnectedMessage (message = ''): void {
        this._send(this._createMessage('connected', message))
    }

    sendConnectErrorMessage (message: string): void {
        this._send(this._createMessage('connect-error', message))
    }

    _send (message: Message): void {
        if (!this.parent) {
            return
        }

        // @ts-ignore
        this.parent.postMessage(JSON.stringify(message), '*')
    }

    _createMessage (code: string, message = ''): Message {
        return { type: this.type, code, message }
    }

    public static createPostMessageEvent (eventType: string, detail?: Message) {
        if (detail === undefined) {
            detail = {}
        }
        detail.type = eventType
        const event = new CustomEvent('app-event', { detail })
        window.dispatchEvent(event)
    }
}
