import Vue from 'vue'
import { PluginObject } from 'vue/types/umd'
import App from './App.vue'
import router from './router'
import store from './store'
import sentryInit from '@/sentry'

/* eslint-disable-next-line */
// @ts-ignore
import UiLibrary from '@ui-kit/core/dist/ui-kit.common'
import '@/assets/static'
import i18n from './i18n'
import PostMessages from '@/shared/frame'

export type StatusResponse = facebook.StatusResponse

declare global {
    interface Window { loginHandler: (resp: StatusResponse) => void; FBReady: boolean }
}

Vue.config.productionTip = false
sentryInit()

// для фрейма показываем отдельный оверлей и инициируем сообщения между окнами
if (window !== top) {
    const postMessages = new PostMessages()
    window.addEventListener('message', (event) => {
        postMessages.listenMessage(event)
    })

    document.addEventListener('DOMContentLoaded', function () { // Аналог $(document).ready(function(){
        postMessages.sendReadyMessage()
    })

    localStorage.setItem('isFrame', '1')
}

/* eslint-disable-next-line */
Vue.use(UiLibrary as PluginObject<Record<string, any>>)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
