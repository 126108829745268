import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { actions } from '@/store/actions'
import { mutations } from '@/store/mutations'
import { getters } from '@/store/getters'
import Connection from '@/api/models/Connection'

export interface State {
    loading: boolean;
    connection: Connection;
}

const rootState: State = {
    loading: false,
    connection: {
        apiUrl: '',
        apiKey: '',
        active: false
    }
}

Vue.use(Vuex)

const store: StoreOptions<State> = {
    state: rootState,
    mutations,
    getters,
    actions
}

const state = new Vuex.Store<State>(store)

export default state
