import BaseService from '@/api/services/index'
import StatusResponse = facebook.StatusResponse
import FacebookStatic = facebook.FacebookStatic

export class FacebookService extends BaseService {
    public init (appId: string): Promise<FacebookStatic> {
        return new Promise((resolve, reject) => {
            window.fbAsyncInit = function () {
                FB.init({
                    appId: appId,
                    cookie: true,
                    xfbml: true,
                    version: 'v14.0'
                })

                FB.Event.subscribe('xfbml.render', () => {
                    window.FBReady = true
                    resolve(FB)
                })
            }

            setTimeout(() => {
                (function (d, s, id) {
                    let js = d.getElementsByTagName(s)[0]
                    const fjs = d.getElementsByTagName(s)[0]
                    if (d.getElementById(id)) {
                        return
                    }
                    js = d.createElement(s)
                    js.id = id
                    js.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js')
                    // @ts-ignore
                    fjs.parentNode.insertBefore(js, fjs);
                    (js as HTMLScriptElement).onerror = (err: Event|string) => reject(err)
                }(document, 'script', 'facebook-jssdk'))
            }, 500)
        })
    }

    public isLogged (firstTry = true): Promise<boolean|null> {
        return new Promise((resolve, reject) => {
            try {
                FB.getLoginStatus(({ authResponse, status }) => {
                    if (authResponse) {
                        resolve(true)
                    } else {
                        if (typeof status !== 'undefined') {
                            if (firstTry && status === 'unknown') {
                                return this.isLogged(false).then(
                                    result => resolve(result),
                                    error => reject(error)
                                )
                            }

                            resolve(false)
                        } else {
                            reject(new Error('meta unavailable'))
                        }
                    }
                })
            } catch (err) {
                reject(err)
            }
        })
    }

    public logout (): Promise<void> {
        return new Promise(resolve => {
            FB.getLoginStatus((resp) => {
                if (resp.status === 'connected') {
                    FB.logout()
                }
            })
            resolve()
        })
    }

    public login (permissions: string[]): Promise<StatusResponse> {
        return new Promise(resolve => {
            FB.login(
                (result) => {
                    resolve(result)
                },
                { scope: permissions.join(',') }
            )
        })
    }
}

const facebookService = new FacebookService()
export default facebookService
