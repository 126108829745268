import BaseService from '@/api/services/index'
import SettingsResponse from '@/api/models/SettingsResponse'

export class SettingsService extends BaseService {
    public async get (): Promise<SettingsResponse> {
        return await this.api.get<void, SettingsResponse>(
            this.getUrl('/settings')
        )
    }
}

const settingsService = new SettingsService()
export default settingsService
