import { ActionContext, ActionTree } from 'vuex'
import { State } from '@/store/index'
import Connection from '@/api/models/Connection'
import connectionService from '@/api/services/ConnectionService'
import accountService from '@/api/services/AccountService'
import { RootStateMutations } from '@/store/mutations'
import { getAuthToken, setAuthToken, deleteAuthToken } from '@/api/service'
import axios from 'axios'
import Account from '@/api/models/Account'
import CreateAccountRequest from '@/api/models/CreateAccountRequest'
import settingsService from '@/api/services/SettingsService'
import facebookService from '@/api/services/FacebookService'
import AddPagesRequest from '@/api/models/AddPagesRequest'

export const actions: ActionTree<State, State> = {
    async signIn (context: ActionContext<State, State>, connection: Connection) {
        let newConnection = false

        try {
            const response = await connectionService.create(connection)

            connection = response.connection || connection
            newConnection = true

            if (connection.clientId != null && connection.clientId.length > 0) {
                setAuthToken(connection.clientId)
            }
        } catch (err) {
            if (!axios.isAxiosError(err) || err?.response?.status !== 409) {
                throw err
            }

            connection = await connectionService.login(connection)

            if (connection.clientId == null || connection.clientId.length === 0) {
                throw new Error('Internal error: empty clientId')
            }

            setAuthToken(connection.clientId)
        }

        context.commit(RootStateMutations.setConnection, connection)

        return newConnection
    },
    async loadConnection (context: ActionContext<State, State>) {
        const token: string = getAuthToken()
        if (token.length === 0) {
            throw new Error('empty token')
        }

        let connection!: Connection
        setAuthToken(token)

        try {
            connection = await connectionService.login({
                apiUrl: '',
                apiKey: '',
                active: true
            })
        } catch {
            deleteAuthToken()
        }

        if (typeof connection === 'undefined' || connection.clientId == null || connection.clientId.length === 0) {
            throw new Error('Internal error: empty clientId')
        }

        context.commit(RootStateMutations.setConnection, connection)
    },
    async addAccountOAuth (context: ActionContext<State, State>, acc: AddPagesRequest) {
        const response = await accountService.addOAuth(acc)
        context.commit(RootStateMutations.setAccounts, response.accounts)
        context.commit(RootStateMutations.updateConnection, response)
    },
    async updateAccount (context: ActionContext<State, State>, acc: Account) {
        await accountService.update(acc)
        context.commit(RootStateMutations.updateAccount, acc)
    },
    async deleteAccount (context: ActionContext<State, State>, acc: Account) {
        await accountService.delete(acc)
        context.commit(RootStateMutations.deleteAccount, acc.id)
    },
    loadSettings () {
        return settingsService.get()
    },
    loadLogs (context: ActionContext<State, State>, acc: Account) {
        return accountService.logs(acc.token)
    },
    async logout (context: ActionContext<State, State>, conn?: Connection) {
        if (conn?.id) {
            const updateConn = await connectionService.logout(conn)
            context.commit(RootStateMutations.updateConnection, updateConn)
        }
    },
    uploadHistory (context: ActionContext<State, State>, channel: number) {
        const token = getAuthToken()
        if (token.length === 0) {
            throw new Error('empty token')
        }

        return accountService.uploadHistory(token, channel)
    },
    fbLogin (context: ActionContext<State, State>, permissions: string[]) {
        return facebookService.login(permissions)
    },
    fbLogout () {
        return facebookService.logout()
    },
    async addAccount (context: ActionContext<State, State>, acc: CreateAccountRequest) {
        const response = await accountService.add(acc)
        context.commit(RootStateMutations.setAccounts, response.accounts)
    },
    async enableOAuth (context: ActionContext<State, State>, conn: Connection) {
        await connectionService.enableOAuth(conn)
        conn.oAuthEnabled = true
        context.commit(RootStateMutations.updateConnection, conn)
    }
}
