import BaseService from '@/api/services/index'
import MessageResponse from '@/api/models/MessageResponse'
import Account from '@/api/models/Account'
import AddPagesRequest from '@/api/models/AddPagesRequest'
import Connection from '@/api/models/Connection'
import AccountLog from '@/api/models/AccountLog'
import CreateAccountRequest from '@/api/models/CreateAccountRequest'

export class AccountService extends BaseService {
    public async addOAuth (acc: AddPagesRequest): Promise<Connection> {
        return await this.api.post<AddPagesRequest, Connection>(
            this.getUrl('/account/acquire-bots'),
            acc
        )
    }

    public async add (acc: CreateAccountRequest): Promise<Connection> {
        return await this.api.post<CreateAccountRequest, Connection>(
            this.getUrl('/account/add'),
            acc
        )
    }

    public async update (acc: Account): Promise<MessageResponse> {
        return await this.api.post<Account, MessageResponse>(
            this.getUrl('/account/update'),
            acc
        )
    }

    public async delete (acc: Account): Promise<MessageResponse> {
        return await this.api.post<Account, MessageResponse>(
            this.getUrl('/account/delete'),
            acc
        )
    }

    public async logs (token: string): Promise<AccountLog[]> {
        return await this.api.get<string, AccountLog[]>(
            this.getUrl('/account/logs/' + token)
        )
    }

    public async uploadHistory (clientID: string, channel: number): Promise<void> {
        return await this.api.post<number, void>(
            this.getUrl('/account/upload-history/' + clientID + '/' + channel),
            channel
        )
    }
}

const accountService = new AccountService()
export default accountService
