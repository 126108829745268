import { RouteConfig } from 'vue-router'
import i18n from '@/i18n'

const routes: Array<RouteConfig> = [
    {
        path: '/settings',
        name: 'settings',
        redirect: { name: 'accounts' }
    },
    {
        path: '/settings/accounts',
        name: 'accounts',
        meta: { title: i18n.t('integration') },
        component: () => import('@/modules/settings/views/Accounts.vue')
    },
    {
        path: '/settings/:clientID',
        name: 'client-accounts',
        redirect: to => {
            return { name: 'accounts', params: { clientID: to.params.clientID } }
        }
    }
]

export default routes
