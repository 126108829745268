import { GetterTree } from 'vuex'
import { State } from '@/store/index'
import Connection from '@/api/models/Connection'
import Account from '@/api/models/Account'

export const getters: GetterTree<State, State> = {
    isLoading: (state: State): boolean => {
        return state.loading
    },
    connection: (state: State): Connection|undefined => {
        return state.connection
    },
    accounts: (state: State): Array<Account> => {
        return state.connection?.accounts || []
    }
}
