import BaseService from '@/api/services/index'
import Connection from '@/api/models/Connection'
import MessageResponse from '@/api/models/MessageResponse'
import ConnectionResponse from '@/api/models/ConnectionResponse'

export class ConnectionService extends BaseService {
    public async create (connection: Connection): Promise<ConnectionResponse> {
        return await this.api.post<Connection, ConnectionResponse>(
            this.getUrl('/connection/create'),
            connection
        )
    }

    public async login (connection: Connection): Promise<Connection> {
        return await this.api.post<Connection, Connection>(
            this.getUrl('/connection/login'),
            connection
        )
    }

    public async update (connection: Connection): Promise<MessageResponse> {
        return await this.api.post<Connection, MessageResponse>(
            this.getUrl('/connection/save'),
            connection
        )
    }

    public async logout (connection: Connection): Promise<Connection> {
        return await this.api.post<Connection, Connection>(
            this.getUrl('/connection/logout'),
            connection
        )
    }

    public async enableOAuth (connection: Connection): Promise<void> {
        return await this.api.post<Connection, void>(
            this.getUrl('/connection/enable-oauth/' + connection.clientId),
            connection
        )
    }
}

const connectionService = new ConnectionService()
export default connectionService
