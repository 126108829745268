import Service, { ApiService } from '@/api/service'

const API_PREFIX = '/api/v1'

export default abstract class BaseService {
    protected api: ApiService;

    constructor () {
        this.api = new Service()
    }

    protected getUrl (route: string): string {
        return API_PREFIX + route
    }
}
