import * as Sentry from '@sentry/vue'
import Vue from 'vue'

export default function sentryInit (): void {
    if (process.env.VUE_APP_SENTRY_DSN) {
        Sentry.init({
            Vue: Vue,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            attachStacktrace: true,
            environment: process.env.NODE_ENV
        })
    }
}
